
import React from 'react';
import './App.css';

const RightSection = ({ selectedOrder, orders }) => {
  const order = orders.find(order => order.userId === selectedOrder);

  return (
    <div className="right-section">
      {order ? (
        <div className="order-details">
          <h2>Order Details</h2>
          <p><strong>User ID:</strong> {order.userId}</p>
          <p><strong>Distance:</strong> {order.distance}</p>
        </div>
      ) : (
        <div className="no-order">No order selected</div>
      )}
    </div>
  );
};

export default RightSection;
