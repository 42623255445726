import React from 'react';
import './App.css';

const LeftSection = ({ orders, selectedOrder, onSelectOrder }) => {
  const sortedOrders = [...orders].sort((a, b) => a.distance - b.distance);

  const getBackgroundColor = (distance) => {
    if (distance < 100) {
      return 'green-bg';
    } else if (distance < 500) {
      return 'yellow-bg';
    } else {
      return 'default-bg';
    }
  };


  const cardStyles = {
    padding: '20px', 
    marginBottom: '10px', 
    border: '2px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: 'PremiumFont',
    fontSize: '25px',
  };

  return (
    <div className="left-section">
      {sortedOrders.map(order => (
        <div
          key={order.userId}
          className={`order-card ${selectedOrder === order.userId ? 'selected' : ''} ${getBackgroundColor(order.distance)}`}
          style={cardStyles}
          onClick={() => onSelectOrder(order.userId)}
        >
          <h3 style={{ fontSize: '24px' }}>{order.name}</h3>
          <p>User ID: {order.userId}</p>
          <p>Distance: {order.distance} Meters</p>
        </div>
      ))}
    </div>
  );
};

export default LeftSection;
