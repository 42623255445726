import React from 'react';
import './App.css';

const Toolbar = () => {
  return (
    <div>
      <div className="toolbar">
        <div className="company-name">Lowes</div>
      </div>
      <div className="subtoolbar">
        <div className="company-name">Store - 1001</div>
      </div>
    </div>
  );
};

export default Toolbar;
