import React, { useState, useEffect } from 'react';
import './App.css';
import Toolbar from './Toolbar';
import LeftSection from './LeftSection';
import RightSection from './RightSection';

function App() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [apiUrl, setApiUrl] = useState('http://localhost:8080/orders/get-temp-orders'); // Default API URL
  const [fetchEnabled, setFetchEnabled] = useState(false); // Toggle to enable/disable API fetch

  const fetchOrders = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    if (fetchEnabled) {
      fetchOrders();
      const intervalId = setInterval(() => {
        fetchOrders();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [apiUrl, fetchEnabled]);

  const handleSelectOrder = (orderId) => {
    setSelectedOrder(orderId);
  };

  const handleConfigureApiUrl = () => {
    fetchOrders();
  };

  const toggleFetchEnabled = () => {
    setFetchEnabled(!fetchEnabled);
  };

  return (
    <div className="App">

      <Toolbar />
      <div className="sections">
        <LeftSection orders={orders} selectedOrder={selectedOrder} onSelectOrder={handleSelectOrder} />
        <RightSection orders={orders} selectedOrder={selectedOrder} />
      </div>
      <div className="api-config">
        <input
          type="text"
          className="custom-input"
          placeholder="Enter API URL"
          value={apiUrl}
          onChange={(e) => setApiUrl(e.target.value)}
        />

      </div>
      <br />
      <div>
        <button className="custom-button" onClick={handleConfigureApiUrl}>
          Configure
        </button>
      </div>
      <br />
      <div className="premium-toggle">
        <button
          className={`premium-button ${fetchEnabled ? 'active' : ''}`}
          onClick={toggleFetchEnabled}
        >
          {fetchEnabled ? 'Fetch On' : 'Fetch Off'}
        </button>
      </div>


    </div>
  );
}

export default App;
